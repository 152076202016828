<template>
    <div>
        <!-- <FilterComponent :isUserAdmin="isUserAdmin" @filter="filter" @clearFilters="clearFilters"/> -->
        <AppTable :dataTable="dataTable" :headers="headers">
            <template v-slot:insideTable>
                <ToolbarInsideTable
                    :openNewItem="openNewItem"
                    :tableTitle="setTableTitle"
                    :toolbarBtnText="setBtnNewItemText"
                    @handleNewItem="handleNewItem"
                >
                    <template v-slot:newItem>
                        <NewItem
                            :openNewItem="openNewItem"
                            :editedItem="editedItem"
                            :isProduct="true"
                            :NewItemTitle="setNewItemTitle"
                            :NewItemInputValues="NewItemInputValues"
                            :loadingSave="loadingSave"
                            :validationsProps="validationsProps"
                            :errorsValidationProps="errorsValidationProps"
                            :isEditing="isEditing"
                            @addImagesToDelete="addImagesToDelete"
                            @close="close"
                            @save="save"
                        />
                    </template>
                </ToolbarInsideTable>
            </template>
            <template v-slot:actionsColumnTable="slotProps">
                <ActionColumnTable
                    :item="slotProps.item"
                    :hasDelete="false"
                    @editItem="editItem"
                />
            </template>
        </AppTable>
    </div>
</template>

<script>
import AppTable from "@/components/views/dataTableOne/AppTable.vue";
/* import DeleteItem from '@/components/views/dataTableOne/layoutOne/deleteItem/DeleteItem.vue' */
import ToolbarInsideTable from "@/components/views/dataTableOne/layoutTwo/toolbar/ToolbarInsideTable.vue";
import NewItem from "@/components/views/dataTableOne/layoutTwo/newItem/NewItem.vue";
import ActionColumnTable from "@/components/views/dataTableOne/layoutOne/columnActions/ActionColumnTable.vue";
// import { updateProduct, createProduct } from '@/services/products.js'
import {
    getAllProducts,
    updateProduct,
    createProduct,
} from "@/services/products.js";
// import { getAllUsers } from '@/services/users'
// import FilterComponent from '../orders/Filter'
import { EventBus } from "@/Event-bus.js";
import { mapGetters, mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
    name: "ProductsTable",
    // components: { AppTable, Pagination, ColumnExpand, ExpandedItem, MeliIcon, WooIcon, ProductEditDialog, FilterComponent, NewItem, ToolbarInsideTable },
    components: { AppTable, ActionColumnTable, ToolbarInsideTable, NewItem },
    props: {
        isUserAdmin: Boolean,
    },
    data: () => ({
        openNewItem: false,
        loadingSave: false,
        openItemDelete: false,
        loadingDelete: false,
        expanded: [],
        singleExpand: false,
        selectedMenuItem: null,
        itemsMenuList: [
            { text: "Copiar Enlace", fn: "copyLink" },
            { text: "Modificar Datos Producto", fn: "showChangeProductData" },
        ],
        headers: [
            { text: "ID", value: "id", align: "left" },
            { text: "Sku", value: "sku", align: "left" },
            { text: "Nombre", value: "name", align: "left" },
            { text: "Stock", value: "stock", align: "left" },
            { text: "Precio", value: "price", align: "left" },
            { text: "", value: "actions", sortable: false, align: "left" },
        ],
        NewItemInputValues: [
            { text: "Sku", value: "sku", align: "left" },
            { text: "Nombre", value: "name", align: "left" },
            { text: "Stock", value: "stock", align: "left" },
            { text: "Precio", value: "price", align: "left" },
            {
                text: "Descripción",
                value: "description",
                type: "long-text",
                align: "left",
            },
            {
                text: "Imágen Principal",
                value: "mainImage",
                type: "images",
                align: "left",
            },
            {
                text: "Imágenes",
                value: "pictures",
                type: "images",
                align: "left",
            },
        ],
        validationsProps: {
            editedItem: {
                sku: {},
                name: { required },
                description: { required },
                stock: { required },
                price: { required },
                mainImage: {},
                pictures: {},
            },
        },
        imagesToDelete: [],
        dataTable: [],
        tableOptions: {
            page: 1,
            itemsPerPage: 30,
        },
        paginationOptions: {
            page: 1,
            itemsPerPage: 30,
            pageStart: 0,
            pageStop: 30,
            itemsLength: 30,
        },
        search: "",
        filters: {
            date: "",
            status: "",
            user: "",
        },
        allOrders: [],
        allFiltered: [],
        editedItem: {},
        defaultItem: {},
        editedIndex: -1,
        showPreview: null,
        showDialog: false,
        showDialogProduct: false,
        // currentPage: 0
    }),

    computed: {
        ...mapState(["userToken", "textSearch"]),
        ...mapGetters(["userData", "getTextSearch", "getOrdersFilters"]),
        isEditing() {
            return this.editedIndex !== -1;
        },
    },

    watch: {
        openNewItem(val) {
            val || this.close();
        },
        /* textSearch (newValue) {
      const searchRegex = new RegExp(newValue, 'i')
      this.dataTable = this.allFiltered.filter((event) => {
        if (!newValue || searchRegex.test(event.id_external) || searchRegex.test(event.email)) return event

        if (event.products_order && event.products_order.length > 0) {
          for (let i = 0; i < event.products_order.length; i++) {
            if (searchRegex.test(event.products_order[i].name) || searchRegex.test(event.products_order[i].last_name)) return event
          }
        }
      })
    }, */
        paginationOptions: {
            handler() {
                this.tableOptions.page = this.paginationOptions.page;
            },
        },
    },

    created() {
        this.filters = this.getOrdersFilters;
        this.search = this.getTextSearch;
        EventBus.$on("doSearch", async () => {
            this.search = this.getTextSearch;
            this.handleLoading(true);
            await this.getProducts(
                1,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            this.handleLoading(false);
        });
        this.initialize();
    },

    methods: {
        ...mapActions([
            "signout",
            "handleLoading",
            "handleAlert",
            "handleSalesFilters",
        ]),
        async save() {
            if (this.editedIndex > -1) {
                await this.updateOne();
            } else {
                await this.createOne();
            }
        },
        addImagesToDelete(isMain, isClear, data) {
            if (isClear) {
                data.forEach((i) => {
                    this.imagesToDelete.push(i);
                });
            } else {
                this.imagesToDelete.push(data);
            }
        },
        errorsValidationProps(value, item) {
            if (!item.required) return true;
        },
        setTableTitle() {
            return "Lista de Productos";
        },
        handleNewItem(value) {
            this.openNewItem = value;
        },

        editItem(item) {
            this.imagesToDelete = [];
            this.editedIndex = this.dataTable.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.openNewItem = true;
        },

        close() {
            this.openNewItem = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        setNewItemTitle() {
            return this.editedIndex === -1
                ? "Agregar Producto"
                : "Editar Producto";
        },
        async createOne() {
            try {
                this.loadingSave = true;
                const res = await this.createAProduct(this.editedItem);
                this.handleMessageSuccessApi(res.data.message);
                this.dataTable.push(this.formatDataTable(res.data.new_product));
                this.close();
            } catch (error) {
                console.log("error", error);
                this.handleMessageErrorApi(error);
            } finally {
                this.loadingSave = false;
            }
        },
        async updateOne() {
            try {
                this.loadingSave = true;
                const res = await this.updateAProduct(this.editedItem);
                this.handleMessageSuccessApi(res.data.message);
                Object.assign(
                    this.dataTable[this.editedIndex],
                    this.formatDataTable(res.data.new_product)
                );
                this.close();
            } catch (error) {
                this.handleMessageErrorApi(error);
            } finally {
                this.loadingSave = false;
            }
        },
        async initialize() {
            this.handleLoading(true);
            // const date = this.filters.date !== [] ? this.filters.date : ''
            await this.getProducts(this.user);
            await this.handleExpandedItem();
            // await this.setPage()
            this.handleLoading(false);
        },
        doFormData(item, isEdit) {
            const formData = new FormData();
            formData.append("sku", item.sku ? item.sku : "");
            formData.append("name", item.name);
            formData.append("description", item.description);
            formData.append("stock", item.stock);
            const clearedPrice =
                item.price[0] === "$"
                    ? Number(item.price.slice(1, item.price.length))
                    : Number(item.price);
            formData.append("price", clearedPrice);
            if (!isEdit) {
                // carga mainImage
                let hasMain = false;
                if (item.mainImage) {
                    formData.append("pictures[0][name]", item.mainImage.name);
                    formData.append("pictures[0][file]", item.mainImage);
                    formData.append("pictures[0][is_main]", 1);
                    hasMain = true;
                }
                if (item.pictures) {
                    if (item.pictures.length > 0) {
                        item.pictures.forEach((p, i) => {
                            const index = hasMain ? i + 1 : i;
                            formData.append(
                                "pictures[" + index + "][name]",
                                p.name
                            );
                            formData.append("pictures[" + index + "][file]", p);
                            formData.append(
                                "pictures[" + index + "][is_main]",
                                0
                            );
                        });
                    }
                }
            } else {
                var pos = 0;
                if (this.imagesToDelete.length > 0) {
                    this.imagesToDelete.forEach((i) => {
                        formData.append("pictures[" + pos + "][id]", i.id);
                        pos++;
                    });
                }
                if (item.pictures.length > 0) {
                    item.pictures.forEach((i) => {
                        if (!i.id) {
                            formData.append(
                                "pictures[" + pos + "][name]",
                                i.name
                            );
                            formData.append("pictures[" + pos + "][file]", i);
                            formData.append(
                                "pictures[" + pos + "][is_main]",
                                0
                            );
                            pos++;
                        }
                    });
                }
                // si main image llega como array
                if (item.mainImage.length > 0) {
                    item.mainImage.forEach((i) => {
                        if (!i.id) {
                            formData.append(
                                "pictures[" + pos + "][name]",
                                i.name
                            );
                            formData.append("pictures[" + pos + "][file]", i);
                            formData.append(
                                "pictures[" + pos + "][is_main]",
                                1
                            );
                            pos++;
                        }
                    });
                    // si main image llega como objeto
                } else if (
                    item.mainImage !== [] &&
                    item.mainImage !== undefined
                ) {
                    formData.append(
                        "pictures[" + pos + "][name]",
                        item.mainImage.name
                    );
                    formData.append(
                        "pictures[" + pos + "][file]",
                        item.mainImage
                    );
                    formData.append("pictures[" + pos + "][is_main]", 1);
                    pos++;
                }
            }
            return formData;
        },
        async updateAProduct(item) {
            try {
                const formData = await this.doFormData(item, true);
                const res = await updateProduct(
                    this.editedItem.id,
                    formData,
                    this.userToken
                );
                this.handleMessageSuccessApi(res.data.message);
                return res;
            } catch (error) {
                console.log("error", error);
                this.handleMessageErrorApi(
                    error || "Error actualizando el producto."
                );
                return error;
            }
        },
        async createAProduct(item) {
            try {
                const formData = await this.doFormData(item, false);
                const res = await createProduct(formData, this.userToken);
                this.handleMessageSuccessApi(res.data.message);
                return res;
            } catch (error) {
                console.log("error", error);
                this.handleMessageErrorApi(
                    error || "Error creando el producto."
                );
                return error;
            }
        },
        async getProducts(page, q, dates, state, user) {
            try {
                const res = await getAllProducts(
                    this.userToken,
                    page,
                    q,
                    dates,
                    state,
                    user
                );
                this.allOrders = this.formatDataTable(res.data);
                this.dataTable = this.allOrders;
                this.updatePagination(
                    page,
                    res.data.total,
                    res.data.from,
                    res.data.to
                );
            } catch (error) {
                console.log("error", error);
                this.handleMessageErrorApi(error);
            }
        },

        formatDataTable(data) {
            if (Array.isArray(data)) {
                data.forEach((e) => {
                    this.assignData(e);
                });
            } else {
                this.assignData(data);
            }
            return data;
        },
        assignData(e) {
            e.price = e.actual_price !== null ? `$${e.actual_price}` : "$";
            e.pictures.forEach((p) => {
                p.index = e.pictures.indexOf(p);
            });
            e.mainImage =
                e.pictures.length > 0
                    ? e.pictures.filter((i) => i.is_main === 1)
                    : [];
            e.pictures =
                e.pictures.length > 0
                    ? e.pictures.filter((i) => i.is_main === 0)
                    : [];
        },
        formatProductItems(data) {
            const array = [{ value: 0, text: "Sin usuario asignado" }];
            data.forEach((u) => {
                array.push({
                    value: u.id,
                    text: u.name + " " + u.last_name + " - " + u.roles[0].name,
                });
            });
            return array;
        },
        assignUserItemData(u) {
            u.value = u.name + "" + u.last_name + " " + u.role.name;
        },

        handleSelectedMenuItem(val) {
            this.editedItem = val.itemPreview;
            this.editedIndex = this.dataTable.indexOf(val.itemPreview);
            this[val.item.fn](); // llamo a la funcion de cada item seteada
        },
        copyLink() {
            let baseurl = null;
            if (process.env.VUE_APP_API_URL_MODE === "development") {
                baseurl = process.env.VUE_APP_ADMIN_URL_DEV;
            } else {
                baseurl = process.env.VUE_APP_ADMIN_URL_PROD;
            }
            var aux = document.createElement("input");
            aux.setAttribute("value", `${baseurl}/order/${this.editedItem.id}`);
            document.body.appendChild(aux);
            aux.select();
            document.execCommand("copy");
            document.body.removeChild(aux);
            this.handleMessageSuccessApi("Enlace copiado al portapapeles");
        },
        showChangeProductData() {
            this.showDialogProduct = true;
        },
        /* setPage () {
      if (localStorage.getItem('currentPageSales')) {
        this.currentPage = Number(localStorage.getItem('currentPageSales'))
      } else {
        this.currentPage = 1
      }
    }, */

        async updateTable(paginationData) {
            this.handleLoading(true);
            await this.getProducts(
                paginationData.page,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            // await this.setPage()
            // localStorage.setItem('currentPageSales', page.page)
            this.handleLoading(false);
        },

        async getMainOrder(order) {
            /* this.handleLoading(true)
      await this.getProducts(1, order, this.filters.date, this.filters.status, this.filters.user)
      await this.handleExpandedItem()
      // await this.setPage()
      // localStorage.setItem('currentPageSales', page.page)
      this.handleLoading(false) */
            EventBus.$emit("changeTextSearch", order);
        },

        updatePagination(page, lastPage, pageStart, pageStop) {
            this.paginationOptions.page = page;
            this.paginationOptions.itemsLength = lastPage;
            this.paginationOptions.pageStart = pageStart - 1;
            this.paginationOptions.pageStop = pageStop;
        },

        handleExpandedItem(val) {
            this.expanded = this.allOrders;
        },

        handleMessageSuccessApi(message) {
            this.alertColor = "success";
            this.alertMessage = message;
            this.handleAlert({
                value: true,
                text: this.alertMessage,
                color: this.alertColor,
            });
        },

        handleMessageErrorApi(error) {
            this.alertColor = "error";
            if (error.status === 401) {
                this.signout();
                this.alertMessage = "Sesión caducada";
            } else {
                if (error.status === 422) {
                    const firstError = Object.keys(error.data.errors)[0];
                    this.alertMessage = error.data.errors[firstError][0];
                } else {
                    this.alertMessage = error.status;
                }
            }
            this.handleAlert({
                value: true,
                text: this.alertMessage,
                color: this.alertColor,
            });
        },
        formatForUpdateUser(data) {
            return {
                name: data.client.name,
                last_name: data.client.last_name,
                email: data.client.email,
                client_address: data.client_address,
                client_city: data.client_city,
                client_province: data.client_Province,
                client_postcode: data.client_postcode,
                id_order: data.id,
            };
        },
        closeDialog(dialog) {
            dialog === "state"
                ? (this.showDialog = false)
                : dialog === "product"
                ? (this.showDialogProduct = false)
                : dialog === "assignUser"
                ? (this.showDialogAssignUser = false)
                : (this.showDialogComentary = false);
        },

        getColor(value) {
            let result = "";
            if (value.state.id === 5) {
                result =
                    value.shipping.includes("Retiro oficina") ||
                    value.shipping.includes("Retiro por local")
                        ? "Pedido listo para retirar"
                        : "Pedido listo para despachar";
            } else {
                result = value.state.name;
            }
            const colors = {
                Aprobado: "rgba(232, 0, 237, 1)",
                "En produccion": "rgba(255, 114, 0, 0.35)",
                "Pedido listo para retirar": "rgba(0, 237, 137, 1)",
                "Pedido listo para despachar": "rgba(0, 150, 137, 1)",
                "Pedido casi listo": "rgba(142, 0, 237)",
                Cancelado: "rgba(237, 71, 0, 1)",
            };
            return colors[result] || "rgba(255, 114, 0, 0.35)";
        },

        setIcon(value) {
            const icon = {
                Meli: "MeliIcon",
                Woo: "WooIcon",
            };
            value = value === 2 ? "Woo" : "Meli";
            return icon[value] || "div";
        },

        async filter() {
            this.handleLoading(true);
            this.filters = this.getOrdersFilters;
            /* this.getOrdersFilters.status === 0
        ? this.filters.status = ''
        : this.filters.status = this.getOrdersFilters.status
      this.getOrdersFilters.date.length === 0
        ? this.filters.date = ''
        : this.filters.date = this.getOrdersFilters.date.replace(' - ', ',')
      this.getOrdersFilters.user === 0
        ? this.filters.user = ''
        : this.filters.user = this.getOrdersFilters.user */

            await this.getProducts(
                1,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            // await this.setPage()
            // localStorage.setItem('currentPageSales', page.page)
            // this.dataTable = this.allFiltered
            this.handleLoading(false);
        },

        async clearFilters() {
            this.handleLoading(true);
            this.filters.date = "";
            this.filters.status = 0;
            this.filters.user = 0;
            this.handleSalesFilters({ date: "", status: 0, user: 0 });
            await this.getProducts(
                1,
                this.search,
                this.filters.date,
                this.filters.status,
                this.filters.user
            );
            await this.handleExpandedItem();
            this.handleLoading(false);
        },

        setBtnNewItemText() {
            return "Nuevo";
        },
    },
};
</script>

<style lang="scss" scoped></style>
