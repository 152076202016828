<template>
  <div :class="$vuetify.breakpoint.xs ? 'mx-4 mt-4' : 'mx-8 mt-8'">
    <ProductsTable :isUserAdmin="isAdmin" />
  </div>
</template>

<script>
import ProductsTable from '@/components/products/ProductsTable'
import { mapGetters } from 'vuex'

export default {
  name: 'Products',

  data: () => ({
    isAdmin: false
  }),

  components: {
    ProductsTable
  },

  mounted () {
    if (this.userRoles) {
      this.userRoles.some(r => r.id === 1)
        ? this.isAdmin = true
        : this.isAdmin = false
    }
  },

  computed: {
    ...mapGetters(['userRoles'])
  },

  watch: {
    userRoles: {
      handler () {
        if (this.userRoles) {
          this.userRoles.some(r => r.id === 1)
            ? this.isAdmin = true
            : this.isAdmin = false
        }
      }
    }
  }
}
</script>

<style scoped>
</style>
